import Error from './Error.vue'

import { createApp } from 'vue'

const app = createApp(
  {
    components: { Error },
    template: '<Error/>'
  }
)
// Vue config
app.config.__VUE_PROD_DEVTOOLS__ = process.env.NODE_ENV === 'development'

app.mount('body')
